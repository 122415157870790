import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../../Cards/Poker/DesktopTitle.scss'
import GameTitle from './GameTitle'
import MoreInfo from '../../Cards/Poker/MoreInfo'
import WinnersToday from '../../Cards/Poker/WinnersToday'

const DesktopTitle: React.FC = () => {
  return (
    <Container>
      <Row id="DesktopTitle">
        <Col>
          <GameTitle />
        </Col>

        <Col className="d-info">
          <MoreInfo category="casual" playerCount="2 - 4" />
          <WinnersToday
            count={29}
            amount="9,289"
            amtFactor={90}
            countFactor={3.2}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default DesktopTitle
