import React, { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'

import './DownloadBottomSheet.scss'
import { animated, useSpring } from 'react-spring'
import Title from '../Title/Title'
import RatingAndDownloads from '../Index/MainScreen/RatingAndDownloads'
import FullscreenVideo from '../FullscreenVideo/FullscreenVideo'
import DownloadHandler from '../DownloadHandler/DownloadHandler'

interface Props {
  show: boolean
  hideBottomSheet(): void
  downloadLink?: string
}

const DownloadBottomSheet: React.FC<Props> = ({
  show,
  hideBottomSheet,
  downloadLink,
}) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "getmega-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      close: file(relativePath: { eq: "close-menu.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `)

  const slide = useSpring({
    bottom: show ? '0' : '-100%',
  })
  const fade = useSpring({
    opacity: show ? 1 : 0,
    pointerEvents: show ? 'inherit' : 'none',
  })

  const src =
    'https://player.vimeo.com/video/475764833?title=0&byline=0&portrait=0&autoplay=1&background=1'
  const [showVideo, setShowVideo] = useState(false)

  const bsRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const el = bsRef.current
    if (show) {
      if (el) {
        disableBodyScroll(el)
      }
    } else if (el) enableBodyScroll(el)

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [show])

  return (
    <animated.div className="sheet-container" style={fade}>
      <animated.div id="download-bottom-sheet" style={slide} ref={bsRef}>
        <div className="header">
          <GatsbyImage
            image={data.logo.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="Mega logo"
          />
          <div className="app">
            <div className="getmega">Mega App</div>
            <div className="categories">Poker, Carrom, Trivia & more</div>
          </div>
          <button
            className="image-button"
            type="button"
            onClick={(): void => {
              hideBottomSheet()
            }}
          >
            <GatsbyImage
              image={data.close.childImageSharp.gatsbyImageData}
              loading="eager"
              alt="close"
              className="close"
              imgStyle={{ objectFit: 'contain' }}
            />
          </button>
        </div>
        <div className="separator" />
        <Title color="black">
          Don&apos;t you want to play games &amp; win real cash?
        </Title>
        <DownloadHandler apkLink={downloadLink} />
        <div className="rating-download">
          <RatingAndDownloads color="black" />
        </div>
      </animated.div>

      {showVideo && (
        <FullscreenVideo
          src={src}
          close={() => {
            setShowVideo(false)
          }}
        />
      )}
    </animated.div>
  )
}

export default DownloadBottomSheet
