import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const FruitKaatInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about Fruit Kaat</h2>
      <p>
        The market is buzzing. The fruits are ripe. Can you swipe to finish the
        fight? Ready to switch &amp; match fruits in a juicy new match 3 game?
      </p>
      <p>
        Match 3 games are the internet&#39;s favourite timepass. Find patterns,
        swipe left or right, up or down to match 3 items of a kind and wipe them
        off the board. Clear the screen before the clock buzzes. Fun enough for
        a lazy afternoon. Right?
      </p>
      <p>
        What if we told you that you could play Match 3 games online against
        real players in real time? And what if you could win real cash playing
        it!
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Mega brings to you Fruit Kaat, a desi style, multiplayer match 3 game
        where you compete with real players to win real money. Swipe and match 3
        of your opponents’ fruits to reduce their health, or match your own
        fruits to save yourself. Swipe speedily to sweetly crush your opponents
        and win real cash.
      </p>
      <p>Ready to slice, dice and take the prize?</p>

      <h3>Rules</h3>
      <p>
        Fruit Kaat is a match 3 style game played between 2-4 players. Each
        player starts with a fixed score and health, and the aim is to ‘race to
        zero’ — reduce your score to zero before your health runs out.
      </p>
      <ul>
        <li>
          <p>
            Each player starts with 100 points, and 50 health and gets a fruit
            assigned.
          </p>
        </li>
        <li>
          <p>
            Each player gets 3 swipes in their turn before the timer runs out.
          </p>
        </li>
        <li>
          <p>Swipe left/right or up/down to match 3 fruits of a kind.</p>
        </li>
        <li>
          <p>
            Matching opponents’ fruits reduces their health by 3 units &amp; our
            score by 3 points. Matching our own fruit increases our health by 3
            points.
          </p>
        </li>
        <li>
          <p>If a player’s health is reduced to 0, they are out of the game.</p>
        </li>
        <li>
          <p>The player that scores 0 the fastest, wins the game.</p>
        </li>
      </ul>

      <h3>Tips</h3>
      <p>
        Remember, there are more than one ways to win in Fruit Kaat — by
        reducing your points to zero faster than others, and also by removing
        others from the game by finishing their health. Plan your moves wisely,
        and balance between reducing opponent health and your own score.
      </p>
      <p>
        Each match of your own fruit increases your health, but takes away a
        chance to attack other players in the turn. It also clears your fruits
        off the board, so your opponents will have to find a different way to
        attack you later. Remember, the clock is always ticking!
      </p>
      <p>
        Keep refining your skills with practice and playing with different kinds
        of opponents. The tables of our match 3 game run as low as ₹1, so that
        you can play as per your comfort level.
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        hidden={hidden}
        gameName="FruitKaat"
      />
    </div>
  )
}

export default FruitKaatInfo
