import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FruitKaatPage from '../../components/Games/Casual/FruitKaat/FruitKaat'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { CASUAL, FRUIT_KAAT, GAMES } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'FruitKaat (Match 3)',
    url: FRUIT_KAAT,
  },
]

const FruitKaat: React.FC = () => {
  const [fruitkaatFaqsSt, setFruitkaatFaqsSt] = useState<Faq[]>([])

  const wyntaLink =
    'https://partner.getmega.com/affiliate/redirect?cid=15508&aid=5088&lang=en&track=&utm_campaign=OrganicRummy'

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ fruitkaatFaqs }) =>
      setFruitkaatFaqsSt(fruitkaatFaqs)
    )
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={wyntaLink}>
      <SEO
        title="Fruitkaat: Play Match 3 Game Online and Win Real Money | Mega"
        description="Crush fruits to defeat your opponents & win real money! Play Fruit Kaat on Mega. Swipe & Match 3 fruits of kind, and beat real players in real time and win real money."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(fruitkaatFaqsSt)}
      />
      <FruitKaatPage playBtnLink={wyntaLink} />
    </Layout>
  )
}

export default FruitKaat
