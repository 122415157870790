import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import '../../Cards/Poker/Poker.scss'
import { Container } from 'react-bootstrap'
import GamesIntro from '../../GamesIntro/GamesIntro'
import Contests from '../../Contests'
import GameTestimonials from '../../Cards/Poker/GameTestimonials'
import OtherGames from '../../Cards/Poker/OtherGames'
import DownloadBottomSheet from '../../../Download/DownloadBottomSheet'
import DesktopTitle from './DesktopTitle'
import FruitKaatInfo from '../../CategoryAndGamesInfo/FruitKaatInfo'
import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import { Faq } from '../../../FAQs/faqs-list-interface'
import useTestimonials from '../../../Testimonials/useTestimonials'
import InfoCard from '../../CategoryAndGamesInfo/InfoCard'
import { recsGameNames } from '../../Cards/Poker/useRecommendGames'

interface Props {
  playBtnLink?: string
}

const FruitKaat: React.FC<Props> = ({ playBtnLink }) => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/Fruit kaat.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [fruitkaatFaqsSt, setFruitkaatFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../FAQs/faqs-list').then(({ fruitkaatFaqs }) =>
      setFruitkaatFaqsSt(fruitkaatFaqs)
    )
  }, [])

  const [showDownload, setShowDownload] = useState(false)
  const hideBottomSheet = (): void => {
    setShowDownload(false)
  }

  const { fruitKaatTestimonials } = useTestimonials()

  return (
    <div id="poker">
      <GamesIntro
        thumbnail={data.intro.childImageSharp.gatsbyImageData}
        addImageMask
        gameName="FruitKaat"
        apkLink={playBtnLink}
      />
      <DesktopTitle />
      <Container>
        <InfoCard>
          <FruitKaatInfo />
        </InfoCard>
      </Container>
      <Contests gameName="FruitKaat" />
      <GameTestimonials testimonials={fruitKaatTestimonials} />
      <div className="faqs-wrap">
        <HomeFAQs faqs={fruitkaatFaqsSt} />
      </div>
      <OtherGames gameName={recsGameNames.FruitKaat} category="Casual" />
      <DownloadBottomSheet
        show={showDownload}
        hideBottomSheet={hideBottomSheet}
      />
    </div>
  )
}

export default FruitKaat
